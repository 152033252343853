<template>
  <swiper class="swiper" :options="swiperOption">
    <swiper-slide v-for="partner in partners" :key="partner.id">
      <div style="height: 100%;" class="slide-item">
        <div
          class="d-flex flex-column justify-center align-center white fill-height"
        >
          <v-img
            alt="4talents Logo"
            class="shrink mb-2"
            contain
            :src="partner.logo.data.full_url"
            transition="scale-transition"
            width="70"
          />
          <!-- :src="require('@/assets/images/4talents.png')" -->
          <div class="primary--text">
            {{ partner[$root.lang == 'ar' ? 'name' : 'enname'] }}
          </div>
          <div class="primary--text">
            {{ partner[$root.lang == 'ar' ? 'country' : 'encountry'] }}
          </div>
        </div>
      </div>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
    <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
  </swiper>
</template>

<script>
export default {
  components: {
    // Swiper,
    // SwiperSlide,
  },
  props: ['partners'],
  data() {
    return {
      swiperOption: {
        slidesPerView: 5,
        slidesPerColumn: 2,
        spaceBetween: 30,
        // centeredSlides: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: true,
          // type: 'progressbar',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
// @import './base.scss';

.swiper {
  height: 480px;
  // padding: 10px;
  // border: 1px solid red;
  // margin-left: auto;
  // margin-right: auto;

  .swiper-slide {
    height: 200px;
  }

  .slide-item {
    box-shadow: 0px 4px 25px #b2b5bb;
    margin: 20px;
    border-radius: 8px;
    overflow: hidden;
  }
}
</style>
